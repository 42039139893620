export const module = {
  info: {
    fullName: 'Root-AppModules',
    namespace: 'Root',
    name: 'AppModules',
    caption: 'AppModules',
    routeName: 'module->Root->AppModules'
  },
  vuexModules: ['api', 'state'],
  mainRoute: '/cpanel',
  // noRoutes: true,
  sideMenu: {
    topSeparator: false,
    caption: 'AppModules',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  }
}

export default module
